<template>
  <div class="disksback">
    <div class="px-3 py-1 mx-0 my-0 flex flex-row justify-between content-between text-gray-50">
      <div class="flex">
        <p class="text-sm">VDIRECTOR</p>
      </div>
      <div class="flex">
        <p class="text-sm">C2-Server</p>
      </div>
      <div class="flex">
        <div class="relative inline-block text-left">
          <div>
            <span class>
              <button
                type="button"
                class="inline-flex justify-center w-full text-sm text-gray-50 hover:text-gray-100 focus:outline-none active:text-gray-200 transition ease-in-out duration-150"
                id="options-menu"
                aria-haspopup="true"
                aria-expanded="true"
                v-on:click.prevent="showLogin=!showLogin"
              >
                Login
                <svg
                  class="-mr-1 ml-2 h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </span>
          </div>
          <div
            v-show="showLogin"
            class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg"
          >
            <div class="rounded-md bg-cool-gray-100 shadow-xs">
              <div
                class="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <div class="my-0 flex flex-col">
                  <div class="flex mx-auto mb-2 text-sm font-semibold text-gray-500">Please sign in:</div>
                  <div class="flex">
                    <input
                      class="text-center mx-auto text-sm rounded-md rounded-b-none focus:outline-none border-b border-gray-100 text-gray-600"
                      type="text"
                      placeholder="username"
                      v-model="login"
                    />
                  </div>
                  <div class="flex">
                    <input
                      class="text-center mx-auto text-sm rounded-md rounded-t-none focus:outline-none border-t border-gray-100 text-gray-600"
                      type="password"
                      placeholder="password"
                      v-model="password"
                      v-on:keyup.enter="LOGIN"
                    />
                  </div>
                  <div class="flex mx-auto">
                    <button
                      class="rounded-md bg-cool-gray-800 w-36 mt-2 mb-1 focus:outline-none"
                      v-on:click.prevent="LOGIN"
                    >login</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.disksback {
  background: url("../assets/disks.jpg");
  min-height: 100vh;
  min-width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>

<script>
export default {
  name: "RootHome",
  components: {},
  data() {
    return {
      showLogin: false,
      login: "",
      password: "",
    };
  },
  methods: {
    LOGIN: function () {
      const { login, password } = this;
      this.$store.dispatch("POST_LOGIN", { login, password }).then(
        (response) => {
          localStorage.setItem(
            "USER_OBJECT",
            JSON.stringify(response.data.claims)
          );
          localStorage.setItem(
            "AUTH_HEADER",
            JSON.stringify(response.data.signature)
          );
          this.$store.commit("UPDATE_USER_OBJECT", response.data.claims);
          this.$store.commit("UPDATE_AUTH_HEADER", response.data.signature);
          this.$router.push("/app");
        },
        (error) => {
          if (error.response.status >= 400) {
            localStorage.removeItem("USER_OBJECT");
            localStorage.removeItem("AUTH_HEADER");
            this.$store.commit("UPDATE_USER_OBJECT", null);
            this.$store.commit("UPDATE_AUTH_HEADER", null);
            alert("Something went wrong...");
          }
        }
      );
    },
  },
};
</script>
